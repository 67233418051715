import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { getRolesList, enableOrDisabledRole, getRoleScope, changeRoleSearch, deleteRole } = iceStarkData.store.get('commonAction')?.role;
import { Button, SearchInput, DataTable, Pager, Dialog, FormGroupList, StaticFormGroup } from 'tyb';
import { ConfirmPopout } from 'ucode-components';
import enums from '../../../../assets/enum.json';

@connect(
    (state) => {
        return {
            ...state.role,
            openIds: state.users.openIds,
            permissionIds: state.users.permissionIds || [],
        }
    }, {
    setNavBar,
    changeRoleSearch,
    getRolesList: getRolesList.REQUEST,
    enableOrDisabledRole: enableOrDisabledRole.REQUEST,
    getRoleScope: getRoleScope.REQUEST,
    deleteRole: deleteRole.REQUEST
}
)

class RolePermission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: this.getTableColumns(),
            showEnterpriseDialog: false,
            roleId: ''
        }
    }

    componentDidMount() {
        const { roleSearch, getRolesList } = this.props;
        getRolesList({ ...roleSearch });
    }

    getTableColumns = () => {
        const tableHeadArr = [
            {
                key: 'name',
                dataIndex: 'name',
                title: '角色名称/ID',
                render: (value, record) => (
                    <div>
                        <div className="ellipsis" style={{ width: '100%', cursor: 'default' }} title={value}>{value}</div>
                        <div>{record.id}</div>
                    </div>
                )
            },
            {
                key: 'createdName',
                dataIndex: 'createdName',
                title: '创建者',
            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: '备注',
                render: (value, record) => <div className="moreline-text-ellipsis" title={value}>{value}</div>
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (value, record) =>
                    <div className="table-operate-btn">
                        {this.props.permissionIds.includes('data.organization.role.edit') && <span onClick={() => this.oprations(record, 'edit')}>编辑</span>}
                        {/* {this.props.permissionIds.includes('data.organization.role.status') && <span
                            onClick={() => { this.props.enableOrDisabledRole({ id: record.id, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' }) }}
                        >{record.status == 'ENABLE' ? '禁用' : '启用'}</span>} */}
                        {this.props.permissionIds.includes('data.organization.role.deleted') && <span style={{ position: 'relative' }} onClick={() => this._handleConfirm(record.id)}>
                            删除
                            {this.state.roleId == record.id && <ConfirmPopout
                                onCancel={this.onCancel}
                                onOk={() => {
                                    const { roleId } = this.state;
                                    this._handleConfirm(roleId);
                                    this.props.deleteRole(roleId);
                                }}
                                styles={{ right: '0px' }}
                                strongText={'确定删除该角色？'}
                                text={'角色删除后无法恢复，请谨慎操作'}
                            />}
                        </span>}
                        {this.props.openIds.userType == 'SYSTEM' && record.roleType == 'ENTERPRISE' && <span onClick={() => {
                            this.props.getRoleScope({ id: record.id })
                            this.setState({ roleName: record.name, showEnterpriseDialog: true })
                        }}>使用企业</span>}
                    </div>
            }
        ]
        const creatorObj = {
            key: 'roleType',
            dataIndex: 'roleType',
            title: '角色类型',
            render: (value, record) => <div>{enums.roleType[value] ? enums.roleType[value].name : ''}</div>
        }
        if (this.props.openIds.userType == 'SYSTEM') {
            tableHeadArr.splice(1, 0, creatorObj);
        }
        return tableHeadArr;
    }

    _handleConfirm = (param) => {
        const { roleId } = this.state;
        this.setState({ roleId: param == roleId ? '' : param })
    }

    onCancel = () => {
        this._handleConfirm(this.state.roleId);
    }

    getColumns = () => {
        return this.state.tableColumns.map(column => {
            return column;
        });
    }

    oprations = (record, action) => {
        const editPath = 'dataorganization/dataroleedit';
        const { id, roleType } = record;
        switch (action) {
            case 'create':
                this.props.setNavBar('新增角色');
                this.props.history.push(editPath);
                break;
            case 'edit':
                this.props.setNavBar("编辑角色");
                this.props.history.push(`${editPath}?${queryString.stringify({ id, roleType })}`);
                break;
            default:
                break;
        }
    }

    render() {
        const { showEnterpriseDialog, roleName = '' } = this.state;
        const { roleSearch, roleTableData, totalElements, roleLoading, perPageCountList, getRolesList, roleScope, changeRoleSearch, permissionIds } = this.props;
        let listStyle = roleTableData.length > 0 && !roleLoading;
        return (
            <div className={`table-padding-bottom ${listStyle ? 'table-tr-height50' : 'table-tr-middle'}`}>
                <div className="searchbar-box">
                    {permissionIds.includes('data.organization.role.add') && <Button onClick={() => this.oprations({}, 'create')}>新增角色</Button>}
                    <SearchInput
                        className="search-input-block"
                        placeholder="输入角色名称或ID"
                        onChange={(value) => { changeRoleSearch({ word: value }) }}
                        onSearch={() => { getRolesList({ ...roleSearch, page: 0 }) }}
                        value={roleSearch.word}
                    />
                </div>
                <DataTable
                    columns={this.getColumns()}
                    data={roleTableData}
                    rowKey="id"
                    rowClassName={(value, record) => value.status == 'DISABLE' ? 'table-gray-row' : ''}
                    messageRender={({ data, error }) => {
                        if (error != null) { return error.msg; }
                        if (data == null || data.length === 0) {
                            return permissionIds.includes('data.organization.role.add') ?
                                <span className="text-weak">暂无角色权限，您可以点击左上角<span onClick={() => this.oprations({}, 'create')}>新增角色</span></span> :
                                <span className="text-weak">暂无角色权限</span>;
                        }
                    }}
                    loading={roleLoading}
                />
                {listStyle && <div className="page-padding20">
                    <Pager
                        total={totalElements}
                        current={roleSearch.page}
                        perPageCount={roleSearch.size}
                        perPageCountList={perPageCountList}
                        onPageChange={e => { getRolesList({ ...roleSearch, page: e }) }}
                        onPerPageChange={e => { getRolesList({ ...roleSearch, size: e, page: 0 }) }} // 每页大小
                    />
                </div>}

                <Dialog
                    className="dialog-has-ok"
                    style={{ width: 330 }}
                    title="查看使用企业"
                    visible={showEnterpriseDialog}
                    cancelText="关闭"
                    onCancel={() => { this.setState({ showEnterpriseDialog: false }) }}
                >
                    <FormGroupList>
                        <StaticFormGroup
                            label="角色"
                        >{roleName}</StaticFormGroup>
                        <StaticFormGroup
                            label="正在使用企业"
                        >
                            {roleScope.map((val, index) => {
                                return <div key={index}>{val.name}</div>
                            })}
                        </StaticFormGroup>
                    </FormGroupList>
                </Dialog>
            </div>
        )
    }

}

export default RolePermission;